import { computed, ref } from 'vue';
import { initEvents } from './events';
import { initGuards } from './guards';
import { initHead } from './head';
import { initInterceptors } from './interceptors';
import { initLocale } from './locale';
import { initTags } from './tags';

export const useInit = () => {
  const initializations = ref({
    guards: false
  });

  const initialized = computed(() =>
    Object.values(initializations.value).reduce((acc, value) => acc && value, true)
  );

  initGuards().then(() => initializations.value.guards = true);
  initEvents();
  initInterceptors();
  initHead();
  initLocale();
  initTags();

  return { initialized };
};
