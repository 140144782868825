import type { ApiError } from '@opalecapital/api-client';
import type { Router } from 'vue-router';
import { useRouter } from 'vue-router';
import { useDialog } from '~/stores';

const catchHttpError = (error: ApiError, { router }: { router: Router }) => {
  const dialogStore = useDialog();

  switch (error.response?.status) {
    case 404:
      router.push({ name: '404' });
      break;

    case 401:
      dialogStore.show('http-401-error');
      break;

    default:
      console.error(error);
  }
};

export const useErrors = () => {
  const router = useRouter();
  return {
    catchHttpError: (error: ApiError) => catchHttpError(error, { router })
  };
};
