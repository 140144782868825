import type { Fund, InvestorProfile, Subscription } from '@opalecapital/api-client';
import type { StoreDefinition } from 'pinia';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { SUBSCRIPTION } from '~/constants';

type SubscriptionStep = typeof SUBSCRIPTION.STEPS[keyof typeof SUBSCRIPTION.STEPS];
type SubscriptionStore = {
  steps: Array<SubscriptionStep>;
  activeStepIndex: number;
  subscription: Partial<Subscription>;
  activeStep: SubscriptionStep | undefined;
  localFund: Partial<Fund>;
  localInvestorProfile: Partial<InvestorProfile>;
};
const steps: Array<SubscriptionStep> = Object.values(SUBSCRIPTION.STEPS);

export const useSubscription: StoreDefinition<'subscription', SubscriptionStore> = defineStore(
  'subscription',
  () => {
    const activeStepIndex = ref(0);
    const activeStep = ref<SubscriptionStep>();

    const subscription = ref<Partial<Subscription>>({
      amount: undefined,
      multipleInvestors: undefined,
      otherInvestorEmail: undefined,
      fundId: undefined,
      investorProfileId: undefined,
      feeRate: undefined
    });

    const localFund = ref<Partial<Fund>>({
      id: undefined,
      maxSubscriptionFeeRate: undefined,
      minimalSubscriptionAmount: undefined,
    });

    const localInvestorProfile = ref<Partial<InvestorProfile>>({
      name: undefined,
      emailAddress: undefined
    });

    const previousStep = computed(() => (activeStepIndex.value > 0) ? steps[activeStepIndex.value - 1] : steps[0]);

    const isValidStep = ref(false);
    const validateStep = (isValid: boolean, payload?: Partial<Subscription>) => {
      isValidStep.value = isValid;
      if (isValid && payload) {
        updateSubscription(payload);
      }
    };

    const reset = () => {
      updateActiveStepIndex(0);
      updateLocalFund({});
      validateStep(false);
      subscription.value = {} as Subscription;
    };

    const updateLocalFund = (payload: Partial<Fund>) => {
      localFund.value = payload;
    };

    const updateLocalInvestorProfile = (payload: Partial<InvestorProfile>) => {
      localInvestorProfile.value = payload;
    };

    const updateActiveStepIndex = (stepIndex: number) => {
      activeStepIndex.value = stepIndex;
      activeStep.value = steps[stepIndex];
    };

    const updateSubscription = (payload: Partial<Subscription>) => {
      subscription.value = { ...subscription.value, ...payload };
    };

    const isLastStep = computed(() => activeStep.value === steps.slice(-1)[0]);

    return {
      steps,
      activeStepIndex,
      subscription,
      activeStep,
      localFund,
      localInvestorProfile,
      updateActiveStepIndex,
      updateSubscription,
      updateLocalFund,
      updateLocalInvestorProfile,
      isValidStep,
      isLastStep,
      previousStep,
      reset,
      validateStep,
    };
  });
