<script lang="ts" setup>
import { useDialog } from '~/stores';
import { Dialog } from '~/stores/dialog';

const dialogStore = useDialog();
</script>

<template>
  <div>
    <component
      :is="`${dialogKey}Dialog`"
      v-for="(dialogName, dialogKey) in Dialog"
      :id="`${dialogName}-dialog`"
      :key="`${dialogKey}Dialog`"
      v-model="dialogStore.displayed[dialogName]"
    />
  </div>
</template>
