<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { DialogManager, LoadingContainer, SnackbarManager } from '~/components';
import { useInit } from '~/init';

const { locale } = useI18n();
const { initialized } = useInit();
</script>

<template>
  <v-app
    :key="`app.${locale}`"
    theme="opale-light"
  >
    <dialog-manager />
    <snackbar-manager />

    <router-view v-if="initialized" />
    <v-main v-else>
      <loading-container
        type="circular"
        vertical-align="center"
      />
    </v-main>
  </v-app>
</template>
