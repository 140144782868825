import { Subscription } from '@opalecapital/api-client';

export const STATUS_COLOR = {
  [Subscription.Status.OnGoing]: 'warning',
  [Subscription.Status.WaitingForValidation]: 'warning',
  [Subscription.Status.Processing]: 'info',
  [Subscription.Status.Sent]: 'info',
  [Subscription.Status.SentForSignature]: 'info',
  [Subscription.Status.InvestorSigned]: 'info',
  [Subscription.Status.Signed]: 'info',
  [Subscription.Status.Cancelled]: 'info',
  [Subscription.Status.Validated]: 'success',
  [Subscription.Status.Rejected]: 'disabled',
};

export const STEPS = {
  PROFILE_AND_FUND: 'profile-and-fund',
  AMOUNT: 'amount',
  OVERVIEW: 'overview',
};

export const CONFIRMATION_STEP = 'subscription-sent';
