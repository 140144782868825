import { InvestorProfile } from '@opalecapital/api-client';

export const STATUS_COLOR = {
  [InvestorProfile.Status.Validated]: 'success',
  [InvestorProfile.Status.UnderReview]: 'info',
  [InvestorProfile.Status.OnGoing]: 'warning',
  [InvestorProfile.Status.New]: 'warning',
  [InvestorProfile.Status.Rejected]: 'disabled'
};

export const PROFESSIONAL_CATEGORY_COLOR = {
  [InvestorProfile.ProfessionalCategory.Professional]: 'warning',
  [InvestorProfile.ProfessionalCategory.NotProfessional]: 'info',
  [InvestorProfile.ProfessionalCategory.NotCategorized]: 'disabled',
};
