import { defineStore } from 'pinia';
import { ref } from 'vue';

export enum Dialog {
  ContactForm = 'contact-form',
  Http401Error = 'http-401-error',
}

export const useDialog = defineStore(
  'dialog',
  () => {
    const displayed = ref(Object.fromEntries(
      Object.values(Dialog).map(dialogName => ([dialogName, false]))
    ) as Record<`${Dialog}`, boolean>);

    const hide = (dialogName: `${Dialog}`) => displayed.value[dialogName] = false;
    const show = (dialogName: `${Dialog}`) => displayed.value[dialogName] = true;
    const reset = () => Object.values(Dialog).forEach(dialogName => displayed.value[dialogName] = false);

    return { hide, show, reset, displayed };
  }
);
