import { watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSettings } from '~/stores';

export const initLocale = () => {
  const { availableLocales, locale } = useI18n();
  const availableShortLocales = getAvailableShortLocales();

  const settingsStore = useSettings();

  if (!settingsStore.locale) {
    settingsStore.locale = getPreferredLocale();
  }

  watchEffect(() => {
    if (!settingsStore.locale) {
      return;
    }

    if (availableLocales.includes(settingsStore.locale)) {
      locale.value = settingsStore.locale;
      return;
    }

    if (!settingsStore.shortLocale) {
      console.warn(`Unknown locale '${settingsStore.locale}'`);
      return;
    }

    if (availableLocales.includes(settingsStore.shortLocale)) {
      locale.value = settingsStore.shortLocale;
      return;
    }

    if (availableShortLocales[settingsStore.shortLocale]) {
      locale.value = availableShortLocales[settingsStore.shortLocale];
      return;
    }
  });
};

const getShortLocaleFromLocaleCode = (code: string) => code.split('-')[0];

const getPreferredLocale = () => {
  const { availableLocales, fallbackLocale } = useI18n();
  const availableShortLocales = getAvailableShortLocales();

  const languages = navigator.languages
    ? [...navigator.languages]
    : [navigator.language];

  const userLocalesOrderedByDecreasingPreference = [
    ...languages,
    ...languages.map((l) => getShortLocaleFromLocaleCode(l)),
    fallbackLocale.value.toString(),
  ];

  userLocalesOrderedByDecreasingPreference.forEach((language) => {
    if (availableShortLocales[language]) {
      return availableShortLocales[language];
    }

    if (availableLocales.includes(language)) {
      return language;
    }
  });

  return availableLocales[0];
};

const getAvailableShortLocales = () => {
  const { availableLocales } = useI18n();

  const availableShortLocales: Record<string, string> = {};
  availableLocales.forEach(
    (availableLocale) =>
      (availableShortLocales[getShortLocaleFromLocaleCode(availableLocale)]
        = availableLocale)
  );

  return availableShortLocales;
};
