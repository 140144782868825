import { useApi } from '@opalecapital/api-client';
import { useErrors } from '~/composables';

export const initInterceptors = () => {
  const { interceptors } = useApi();
  const { catchHttpError } = useErrors();

  interceptors.response.use(
    (response) => response,
    (error) => {
      if (401 === error.response.status) {
        catchHttpError(error);
      }
      throw error;
    });
};
