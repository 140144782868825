import type { ApiCredentials, PartnerCompany, User, Uuid } from '@opalecapital/api-client';
import { useApi } from '@opalecapital/api-client';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

export const useAuth = defineStore(
  'auth',
  () => {
    const { apiClient } = useApi();
    const user = ref<Required<User>>();
    const partnerCompany = ref<Required<PartnerCompany>>();
    const partnerCompanyId = ref<Uuid>();

    const credentials = ref<ApiCredentials>();
    const rememberMe = ref(false);

    const login = (
      newUser: Required<User>,
      newPartnerCompany: Required<PartnerCompany>,
      newCredentials?: ApiCredentials,
      newRememberMe?: boolean
    ) => {
      user.value = newUser;
      partnerCompany.value = newPartnerCompany;
      partnerCompanyId.value = newPartnerCompany.id;

      if (newCredentials) {
        credentials.value = { ...newCredentials };
      }

      if (newRememberMe) {
        rememberMe.value = newRememberMe;
      }
    };

    const refresh = (newCredentials: ApiCredentials) => {
      credentials.value = { ...newCredentials };
    };

    const logout = () => {
      user.value = undefined;
      partnerCompany.value = undefined;
      partnerCompanyId.value = undefined;

      credentials.value = undefined;
      rememberMe.value = false;
    };

    const hasRole = (userRole: User.Role) => user.value?.roles.includes(userRole);

    const updatePartnerCompany = async() => {
      if (!partnerCompanyId.value) {
        return;
      }

      try {
        partnerCompany.value = await apiClient.partnerCompany.show(partnerCompanyId.value);
      }
      catch (error) {
        console.error(error);
      }
    };

    return {
      credentials,
      rememberMe,
      partnerCompanyId,
      user: computed(() => user.value),
      partnerCompany: computed(() => partnerCompany.value),
      authenticated: computed(() => user.value != undefined),
      login,
      refresh,
      logout,
      hasRole,
      updatePartnerCompany,
    };
  },
  {
    persist: true
  }
);
