import { useApi } from '@opalecapital/api-client';
import type { RouteLocation } from 'vue-router';
import { useRouter } from 'vue-router';
import { useAuth } from '~/stores';

export const initGuards = async () => {
  const router = useRouter();
  const { apiClient, setCredentials } = useApi();
  const authStore = useAuth();

  const checkCredentials = async () => {
    if (!authStore.credentials?.accessToken || !authStore.partnerCompanyId) {
      return authStore.logout();
    }

    setCredentials(authStore.credentials);

    if (authStore.rememberMe) {
      try {
        await apiClient.auth.refresh();
      }
      catch (error) {
        return authStore.logout();
      }
    }

    try {
      const user = await apiClient.user.show('me');
      const partnerCompany = await apiClient.partnerCompany.show(authStore.partnerCompanyId);
      authStore.login(user, partnerCompany);
    }
    catch (error) {
      authStore.logout();
    }
  };

  const checking = checkCredentials();

  const logGuard = (to: RouteLocation, from: RouteLocation) => {
    console.log(`from "${from.name?.toString()}" to "${to.name?.toString()}"`);
  };

  const authGuard = async (to: RouteLocation, from: RouteLocation) => {
    if (typeof from.name == 'undefined') {
      await checking;
    }

    if (to.meta.requiresAuth && !authStore.authenticated) {
      return { replace: true, name: 'login' };
    }

    if (to.meta.publicOnly && authStore.authenticated) {
      return { replace: true, name: 'dashboard' };
    }
  };

  router.beforeEach(authGuard);
  router.afterEach(logGuard);

  await checking;
};
